<template>
  <v-container fluid >
    <v-row class="justify-center">
      <v-data-table
        v-if="teams"
        :headers="header_team"
        :items="teams"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar dense flat class="teal" dark>
            <v-toolbar-title>Times </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color=""
                  v-on="on"
                  v-bind="attrs"
                  class="mt-2 mb-2"
                  icon
                  x-large
                  @click="dialog = true"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Cadastrar novo time
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
        </template>

        <template v-slot:item.deleted="{ item }"> <!-- eslint-disable-line-->
          <v-icon :color="getColor(item.ref_v_stage_contrato)"
            >mdi-checkbox-marked-circle</v-icon
          >
        </template>
        <template v-slot:item.actions="{ item }"> <!-- eslint-disable-line-->
          <v-btn color="primary" text icon @click="addSellers(item)"><v-icon > mdi-account-multiple-plus</v-icon></v-btn>
          <v-btn  text icon  @click="addRefTeam(item)"> <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z" />
</svg>
</v-btn>
          <v-btn color="primary" text icon @click="showRefTeam(item)"><v-icon > mdi-eye</v-icon></v-btn>

        </template>
      </v-data-table>
    </v-row>
    <v-divider></v-divider>
    <v-dialog
      class="justify-center"
      v-model="dialog"
            v-if="dialog"

      
      max-width="600px"
      transition="dialog-transition"
    >
      <stepTeam
        v-on:update-team="getTeams()"
        v-on:close-dialog="dialog = false"
      />
    </v-dialog>
        <v-dialog
      class="justify-center"
      v-if="dialogStepRef"
      v-model="dialogStepRef"
      
      max-width="900px"
      transition="dialog-transition"
    
    >
            <newRefTeam :idTeam="idteam" v-on:close-dialog="closeSeller()" v-if="dialogStepRef" :enableHeader="true"/>

    </v-dialog>

    <v-dialog
      class="justify-center"
      v-if="dialogStepTwo"
      v-model="dialogStepTwo"
     
      max-width="900px"
      transition="dialog-transition"
    
    >
      <stepSellers :idTeam="idteam" v-on:close-dialog="closeSeller()" v-if="dialogStepTwo" :enableHeader="true"/>
    </v-dialog>


    <v-dialog
      class="justify-center"
      v-if="dialogShowRef"
      v-model="dialogShowRef"
     
      max-width="900px"
      transition="dialog-transition"
    
    >
      <showRef :idTeam="idteam" v-on:close-dialog="closeListRef()" v-if="dialogShowRef" />
    </v-dialog>
  </v-container>
</template>

<script>
import stepTeam from "./stepTeam";
import stepSellers from "./stepSellers";
import TeamMixins from "../../mixins/TeamMixins";
import newRefTeam from "./newRefTeam"
import showRef from "./listRefTeam"

export default {
  mixins: [TeamMixins],
  components: {
    stepTeam,
    stepSellers,
    newRefTeam,
    showRef
  },
  data: () => {
    return {
      teamss: 1,
      teams: "",
      dialog: false,
      dialogStepTwo: false,
      idteam: null,
      dialogStepRef: false,
      dialogShowRef: false,
      header_team: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Ativo", value: "deleted" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  /**
   * @param callback
   */
  created() {},
  mounted() {
    this.getTeams();
  },
  methods: {
    async closeSeller() {
      this.dialogStepTwo = false;
    },
    async closeListRef(){
      this.dialogShowRef = false;

    },
    async addSellers(item) {
      this.idteam = item.id;
      this.dialogStepTwo = true;
    },
    async addRefTeam(item){
      this.idteam = item.id;
      this.dialogStepRef = true;
    },
    async showRefTeam(item){
      this.idteam = item.id;
      this.dialogShowRef = true;
    },
    getColor(status) {
      if (!status) return "success";
      else return "error";
    },
   
  },
};
</script>

<style>
</style>