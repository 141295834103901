<template>
  <v-card>
    <v-toolbar color="teal" dark v-if="enableHeader">
      <v-toolbar-title>Selecionar vendedores</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        class="mb-n10 mt-4"
        hide-details
        append-icon="mdi-magnify"
        name="Nome"
        label="Pesquisar vendedor"
        placeholder="Informe o nome do vendedor..."
        id="id"
        outlined
        v-model="search"
      ></v-text-field>
    </v-card-title>

    <v-data-table
      v-if="sellers"
      v-model="selecionados"
      :headers="headers"
      :items="sellers"
      item-key="id"
      show-select
      :search="search"
    >
      <template v-slot:header.data-table-select></template>       <!-- eslint-disable-line-->

    </v-data-table>
    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <div class="my-2">
        <v-btn color="primary" dark x-large depressed large @click="send()">
          Salvar
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "stepSellers",
  props: ["idTeam", "enableHeader"],
  data: () => {
    return {
      search: null,
      selecionados: [],
      sellers: "",
      sellerss: [],
      headers: [{ text: "Vendedor", value: "nome" }],
      bulk: {
        team: null,
        salesPersons: [],
      },
    };
  },

  created() {
    this.getAllSeller();
  },

  methods: {
    async getAllSeller() {
      let temporary_sellers = [];
      await this.$http.post("/saleperson/import")
      const result = await this.$http.get("/saleperson");
      this.sellers = result.data;
      this.sellers.forEach(function (data) {
        if (data.fk_time === null) {
          temporary_sellers.push(data);
        }
      });
      this.sellers = temporary_sellers;
    },
    async send() {
      let temporary_seller = [];
      try {
        this.selecionados.forEach(function (data) {
          temporary_seller.push(data.id);
        });
        this.bulk.salesPersons = temporary_seller;
        this.bulk.team = this.idTeam;
        await this.$http.put("/saleperson/bulk", this.bulk);
        this.$toast.success("Vendedores adicionados com sucesso");
        setTimeout(() => {
          this.$emit("close-dialog");
        }, 1000);
      } catch (error) {
        this.$toast.error("Erro ao inserir vendedores");
      }
    },
  },
};
</script>

<style>
</style>