<template>
    
<v-data-table
    :headers="headers"
    :items="refteam"
    :items-per-page="5"
    class="elevation-1"
  >
          <template v-slot:item.actions="{ item }"> <!-- eslint-disable-line-->
          <v-btn
            
            text
            icon
            color="red lighten-2"
            @click="deleteRef(item)"
            
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>          </template>
    <template v-slot:top>
          <v-toolbar dense flat class="teal" dark>
            <v-toolbar-title> Listagem de vinculo </v-toolbar-title>

          </v-toolbar>
          <v-divider></v-divider>
        </template></v-data-table>
  
</template>

<script>
export default {
  name: "refTeam",
  props: ["idTeam"],

  data: () => {
    return {
      refteam: [],
      fk_team: null,
      headers: [
        { text: "Time", value: "time" },
        { text: "Tipo Bonificação", value: "tipo" },
        { text: "Produto", value: "produto" },
        {text: "Ações", value: "actions"}
      ],
    };
  },
  created() {
    this.getRefTeam();
  },
  methods: {
      async deleteRef(item){
          try {
               await this.$http.delete(`/refteambonus/${item.id}`);
              this.$toast.success('Removido com sucesso!')
              this.$emit('close-dialog')
          } catch (error) {
              this.$toast.success('Error ao remover!')
          }
      },
    async getRefTeam() {
      try {
        
        const { data } = await this.$http.get(`/refteambonus/team/${this.idTeam}`);
        this.refteam = data.data;
      } catch (error) {
          if(error.response.status === 404){
              this.$toast.error("Nenhum vinculo encontrado, cadastre para visualizar!");
          }else{
              this.$toast.error("Não foi possivel carregar as referencias.");
          }
        
      }
    },
  },
};
</script>

<style>
</style>