<template>
    <v-stepper v-model="e1">
              <v-toolbar color="teal" dark >
      <v-toolbar-title>Cadastro de time</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"><v-icon arge>mdi-close-circle</v-icon></v-btn>
    </v-toolbar>
      <v-stepper-header class="elevation-0">
        <v-stepper-step :complete="e1 > 1" step="1">
          Cadastro de Time
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Selecionar vendedores
        </v-stepper-step>

        <v-divider></v-divider>
        <!-- <v-btn icon v-on:click="$emit('close-dialog')" class="ml-12"
          ><v-icon arge>mdi-close-circle</v-icon></v-btn
        > -->
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card flat>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-text-field
                  name="Time"
                  label="Time"
                  placeholder="Informe o nome do time"
                  v-model="team.name"
                  outlined
                ></v-text-field>
              </v-form>
            </v-card-text>
          </v-card>

          <v-btn color="primary" @click="registerTime(), (e1 = 2)">
            Cadastrar Time
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <stepSeller :enableHeader="false" :idTeam="idteam" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
</template>

<script>
import stepSeller from "./stepSellers";

export default {
  components: {
    stepSeller,
  },
  data: () => {
    return {
      e1: 1,
      teams: "",
      idteam: null,
      team: {
        name: "",
      },

      headers: [{ text: "Vendedor", value: "nome" }],
      header_team: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Ativo", value: "deleted" },
      ],
    };
  },
  created() {
    this.getTeams();
  },
  methods: {
    getColor(status) {
      if (!status) return "success";
      else return "error";
    },
    async getTeams() {
      const result = await this.$http.get("/team");
      this.teams = result.data;
    },

    async registerTime() {
      const result = await this.$http.post("/team", this.team);
      this.idteam = result.data.id;
      //this.bulk.team = result.data.id;
      this.$emit("update-team");
    },
  },
};
</script>

<style>
</style>