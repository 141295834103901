<template>
  <v-card>
    <v-toolbar color="teal" dark v-if="enableHeader">
      <v-toolbar-title>Vincular bonificações e produtos</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-select
              v-model="bonus"
              :items="bonusTypes"
              item-text="nome"
              outlined
              
              label="Tipo de Bonificação"
              return-object
              
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="product"
              :items="products"
              chips
              multiple
              outlined
               item-text="nome"
               item-value="id"
              label="Produtos"
                        
          hide-details
                    menu-props="auto"


            ></v-select>
            {{product}}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <div class="my-2">
        <v-btn color="primary" dark x-large depressed large @click="create">
          Salvar
          
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "stepSellers",
  props: ["idTeam", "enableHeader"],
  data: () => {
    return {
      search: null,
      selecionados: [],
      sellers: "",
      sellerss: [],
      products: null,
      bonus: null,
      product: [],
      refTeam: {
        team: null,
        bonus: null,
        products: [],
      },
      bonusTypes: null,
      headers: [{ text: "Vendedor", value: "nome" }],
      bulk: {
        team: null,
        salesPersons: [],
      },
    };
  },

  created() {
    this.getBonus();
    this.getProducts();
    this.refTeam.team = this.idTeam
  },
  methods: {
      async create(){
          try {
              this.refTeam.products = this.product
              this.refTeam.bonus = this.bonus.id

               await this.$http.post('refteambonus', this.refTeam)
                      this.$toast.success("Vinculo efetuado com sucesso");
          } catch (error) {
                      this.$toast.error("Produto já vinculado para bonificação escolhida!");

          }
      },
    async getProducts() {
      try {
        const { data } = await this.$http.get("/product");
        this.products = data.data;
      } catch (error) {
        this.$toast.error("Não foi possivel carregar os produtos.");
      }
    },
    async getBonus() {
      try {
        const { data } = await this.$http.get("/bonus");
        this.bonusTypes = data.data;
      } catch (error) {
        this.$toast.error("Não foi possivel carregar os produtos.");
      }
    },
  },
};
</script>

<style>
</style>